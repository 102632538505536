import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Box, colors, CircularProgress, Typography, Avatar, IconButton, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons'
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import BackgroundImg from '../../components/BackgroundImg';
import CustomSnackbar from '../../components/CustomSnackbar';
import CustomSwitch from '../../components/dashboard/CustomSwitch';
import SocialMedia from '../../components/SocialMedia';

import api from '../../values/api';
import request from '../../functions/request';
import requestFile from '../../functions/requestFile';

const useStyles = makeStyles(theme => ({
  buttonDark: {
    color: theme.palette.primary.contrastText,
    background: colors.common.black,
    '&:hover': {
      backgroundColor: colors.grey[900],
    },
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    marginBottom: theme.spacing(1),
    marginRight: '-16px'
  },
  spinner: {
    color: theme.palette.common.white
  },
  form: {
    [theme.breakpoints.up(('sm'))]: {
      width: '40%'
    },
    [theme.breakpoints.down(('sm'))]: {
      width: '60%'
    },
    [theme.breakpoints.down(('xs'))]: {
      width: '80%'
    },
  },
  select: {
    minWidth: '100%',
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey[50],
      },
      '&:hover fieldset': {
        borderColor: colors.grey[600],
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.grey[600],
      },
      color: colors.grey[50]
    },
    '& .MuiInputLabel-root': {
      color: colors.grey[50],
      marginRight: theme.spacing(1)
    },
  },
  input: {
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey[50],
      },
      '&:hover fieldset': {
        borderColor: colors.grey[600],
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.grey[600],
      },
      color: colors.grey[50]
    },
    '& .MuiInputLabel-root': {
      color: colors.grey[50]
    },
  },
}));

const EditProfile = () => {
  document.title = 'Bet - Editar Perfil';
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const hiddenFileInput = useRef(null);

  const [profile, setProfile] = useState(null);
  const [showSocial, setShowSocial] = useState(false);
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initFetch();
    // eslint-disable-next-line
  }, []);

  const initFetch = async () => {
    setLoading(true);

    const url = `${api.getUser}/search?_id=${id}`;
    const user = await request(url, null, 'GET');

    if(user.success){
      setProfile(user.user);
      setLoading(false);
    }else {
      setShowMsg({ show: true, text: 'Bet Perfil não encontrado', severity: 'error' });
      setLoading(false);
    };
  };

  const handleSubmit = async () => {
    if(profile.name === ''){ 
      setShowMsg({ show: true, text: 'o nome não pode estar vazio', severity: 'error' });
      return
    };

    const data = {
      avatar: profile.avatar,
      name: profile.name,
      description: profile.description,
      gender: profile.gender,
      profession: profile.profession,
      dob: profile.dob
    };

    const update = await request(api.editProfile, data, 'POST');

    if(update.success){
      setShowMsg({ show: true, text: 'Perfil atualizado com sucesso', severity: 'success' });
    }else {
      setShowMsg({ show: true, text: 'Ocorreu um erro', severity: 'error' });
    };
  };

  const handleGoBack = () => {
    history.push(`/${profile._id}`);
  };

  const handleChangePublic = async () => {
    const updatePublic = await request(api.editProfile, {public: (!profile.public)}, 'POST');

    if(updatePublic.success){
      setProfile(updatePublic.user);
      setShowMsg({ show: true, text: 'Perfil atualizado com sucesso', severity: 'success' });
    }else {
      setShowMsg({ show: true, text: 'Ocorreu um erro', severity: 'error' });
    };
  };

  const handleChangeImage = async (e) => {
    hiddenFileInput.current.click()
  };

  const handleChange = async (e) => {
    setShowMsg({ show: true, text: 'Carregando arquivo', severity: 'info' });
    const file = e.target.files[0];
    const resUpload = await requestFile(file);

    if(resUpload.success){
      const resUpdate = await request(api.editProfile, {avatar: resUpload.filepath}, 'POST');
      if(resUpdate.success){
        setProfile(resUpdate.user);
        setShowMsg({ show: true, text: 'Perfil atualizado com sucesso', severity: 'success' });
      }else {
        setShowMsg({ show: true, text: 'Ocorreu um erro', severity: 'error' });
      };
    }else {
      setShowMsg({ show: true, text: 'Ocorreu um erro', severity: 'error' });
    };
  };

  const handleOpen = () => {
    setShowSocial(!showSocial);
  };

  return (
    <BackgroundImg>
      {loading && 
        <Box
          display='flex'
          justifyContent='center'
          py={4}
        >
          <CircularProgress className={classes.spinner} size={50} />
        </Box>
      }
      {(!loading && profile) &&
        <Box
          py={3}
          display='flex'
          flexDirection='column'
          alignItems='center'
          color={colors.common.white}
        >
          <Typography
            variant='h4'
          >
            Editar Perfil
          </Typography>
          <Box
            display='flex'
            alignItems='flex-end'
            my={1}
          >
            <Avatar
              src={profile.avatar}
              alt={profile.name}
              className={classes.avatar}
            />   
            <IconButton
              color='inherit'
              aria-label='change-avatar'
              onClick={handleChangeImage}
            >
              <Edit />
            </IconButton>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
              accept="image/png, image/jpg, image/jpeg"
            />
          </Box>
          <Box className={classes.form}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography variant='body1'>
                Perfil público
              </Typography>
              <CustomSwitch
                label={' '}
                checked={profile.public}
                handleChange={handleChangePublic}
              />
            </Box>
            <TextField
              id='name-input'
              label='Nome completo'
              fullWidth={true}
              className={classes.input}
              variant="outlined"
              value={profile.name}
              onChange={e => setProfile({ ...profile, name: e.target.value })}
            />
            <TextField
              id='description-input'
              label='Descrição/Bio'
              fullWidth={true}
              className={classes.input}
              variant="outlined"
              value={profile.description}
              onChange={e => setProfile({ ...profile, description: e.target.value })}
            />
            <TextField
              id='profession-input'
              label='Profissão'
              fullWidth={true}
              className={classes.input}
              variant="outlined"
              value={profile.profession}
              onChange={e => setProfile({ ...profile, profession: e.target.value })}
            />
            <FormControl className={classes.select}>
              <InputLabel id="gender-label">Sexo ou gênero</InputLabel>
              <Select
                labelId="gender-label"
                id="gender-select"
                variant="outlined"
                value={profile.gender ? ((profile.gender === 'male' ||profile.gender === 'female') ? profile.gender : '') : ''}
                onChange={e => setProfile({ ...profile, gender: e.target.value })}
                >
                <MenuItem value=''>Select a gender</MenuItem>
                <MenuItem value='male'>Homen</MenuItem>
                <MenuItem value='female'>Mulher</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="date"
              label="Data de nascimento"
              type="date"
              variant="outlined"
              fullWidth={true}
              className={classes.input}
              defaultValue={moment(profile.dob).format('YYYY-MM-DD')}
              onChange={e => setProfile({...profile, dob: e.target.value})}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Grid
              container
              spacing={1}
            >
              <Grid item sm={12} xs={12} className={classes.contentButton}>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.buttonDark}
                  onClick={handleOpen}
                >
                  Editar Redes sociais
                </Button>
              </Grid>
              <Grid item sm={6} xs={12} className={classes.contentButton}>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.buttonDark}
                  onClick={handleSubmit}
                >
                  Salvar perfil
                </Button>
              </Grid>
              <Grid item sm={6} xs={12} className={classes.contentButton}>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.buttonDark}
                  onClick={handleGoBack}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
      {showSocial && 
        <SocialMedia open={showSocial} handleOpen={handleOpen} profile={profile} setProfile={setProfile} setShowMsg={setShowMsg} />
      }
    </BackgroundImg>
  );
};

export default EditProfile;