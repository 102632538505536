import React, { useState } from 'react';
import { makeStyles, Box, FormControl, InputLabel, Input, InputAdornment, IconButton, Button, TextField, colors, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import BackgroundImg from '../../components/BackgroundImg';
import CustomSnackbar from '../../components/CustomSnackbar';

import logo from '../../assets/images/logo-medio.png';
import api from '../../values/api';
import request from '../../functions/request';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  container: {
    [theme.breakpoints.up(('sm'))]: {
      width: '50%'
    },
    [theme.breakpoints.up(('md'))]: {
      width: '40%'
    },
    [theme.breakpoints.down(('xs'))]: {
      width: '75%'
    },
  },
  button: {
    marginTop: theme.spacing(4)
  },
  logo: {
    width: theme.spacing(10)
  },
  input: {
    marginTop: theme.spacing(1)
  }
}));

const Register = () => {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(email=== '' || password === '' || name === ''){ 
      setShowMsg({ show: true, text: 'Todos os campos são obrigatórios', severity: 'error' });
      return
    };
    
    if(password.length < 7){ 
      setShowMsg({ show: true, text: 'A senha deve ter pelo menos 7 caracteres', severity: 'error' });
      return
    };
    
    if(password !== confirmPass){ 
      setShowMsg({ show: true, text: 'A senha deve corresponder', severity: 'error' });
      return
    };

    const res = await request(api.register, {name, email, password}, 'POST');
    if(res.success){
      const auth = await request(api.auth, {email, password}, 'POST');
      if(auth.success){
        localStorage.setItem('token', auth.token);
        localStorage.setItem('uid', auth.user._id);
        const avatar = "https://mypegoficial.com/uploads/278_perfil-avatar-hombre-icono-redondo_24640-14044.jpg";
        const update = await request(api.editProfile, {avatar, public: true}, 'POST');
        if(update.success) history.push(`/${res.user._id}`);
      };
    }else {
      setShowMsg({ show: true, text: 'Este e-mail já está em uso', severity: 'error' });
    };
  };

  return (
    <BackgroundImg>
      <Box className={classes.root}>
        <form className={classes.container} onSubmit={handleSubmit} >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            bgcolor={colors.common.white}
            px={3}
            py={4}
            borderRadius={10}
          >
            <img src={logo} alt="logo-peg" className={classes.logo} />
            <Typography variant='h6'>
              Abrir conta digital
            </Typography>
            <TextField
              id='name-input'
              label='Nome completo'
              fullWidth
              value={name}
              className={classes.input}
              onChange={e => setName(e.target.value)}
            />
            <TextField
              id='email-input'
              label='Email'
              type='email'
              fullWidth
              value={email}
              className={classes.input}
              onChange={e => setEmail(e.target.value.trim())}
            />
            <FormControl fullWidth className={classes.input} >
              <InputLabel htmlFor='password-input'>Senha</InputLabel>
              <Input
                id='password-input'
                type={showPass ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value.trim())}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle-password-visibility'
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth className={classes.input} >
              <InputLabel htmlFor='confirm-password-input'>Confirmar senha</InputLabel>
              <Input
                id='confirm-password-input'
                type={showPass ? 'text' : 'password'}
                value={confirmPass}
                onChange={e => setConfirmPass(e.target.value.replace(' ', ''))}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle-password-visibility'
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              className={classes.button}
              onClick={handleSubmit}
            >
              Abrir conta
            </Button>
          </Box>
        </form>
      </Box>
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
    </BackgroundImg>
  );
};

export default Register;