import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Paper, TextField, Typography, CircularProgress, Grid, Select, MenuItem } from '@material-ui/core';

import Layout from '../../components/dashboard/layout/Layout';
import CustomSnackbar from '../../components/CustomSnackbar';

import api from '../../values/api';
import request from '../../functions/request';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    [theme.breakpoints.down(('xs'))]: {
      width: '150px'
    },
    [theme.breakpoints.up(('md'))]: {
      width: '350px'
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    //alignContent: 'center'
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const ViewRequest = () => {
  document.title='Bet - Request Account';
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });
  
  useEffect(() => {
    initFetch();
    // eslint-disable-next-line
  }, []);

  const initFetch = async () => {
    setLoading(true);
    const res = await request(api.requestAccount);
    if(res.success){
      const data = [];
      for (const element of res.bankAccountRequest){
        const url = `${api.searchUser}?_id=${element.user}`;
        const user = await request(url);
        if(user.success){
          data.push({user: user.user, bankAccountRequest: element});
        }
      }
      setList(data);
    }else {
      setShowMsg({ show: true, text: 'Error cannot load', severity: 'error' });
      setLoading(false);
    };
    setLoading(false);
  };

  const handleChange = async (value, item) => {

    const res = await request(api.requestAccount, {id: item.bankAccountRequest._id, status: value}, 'PUT');
    
    if(res.success){
      initFetch()
      setShowMsg({ show: true, text: 'Request updated', severity: 'error' });
    }else return setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
  };

  return (
    <Layout>
      <Paper>
        <Box
         p={2}
        >
          <Typography>
            Requests
          </Typography>
          <Box>
            <TextField
              id='search-input'
              label='Name or email'
              className={classes.input}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Box>
          {loading &&
            <Box
              display='flex'
              justifyContent='center'
              py={2}
            >
              <CircularProgress />
            </Box>
          }
          <Grid container spacing={1} className={classes.content}>
            {list.map((item, i) => (item.user.name.toLowerCase().includes(search) &&
              <Grid item key={i} xs={12} className={classes.flex}>
                <Box>
                  <Typography variant='body1'>
                    {item.user.name}
                  </Typography>
                  <Typography variant='body2'>
                    {item.user.email}
                  </Typography>
                  <Typography variant='body2'>
                    Request ID: {item.bankAccountRequest._id}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>
                    Status: {item.bankAccountRequest.status}
                  </Typography>
                  <Select
                    labelId="gender-label"
                    id="gender-select"
                    value={item.bankAccountRequest.status}
                    onChange={e => handleChange(e.target.value, item)}
                  >
                    <MenuItem value='sended'>Sended</MenuItem>
                    <MenuItem value='accepted'>Accepted</MenuItem>
                    <MenuItem value='rejected'>Rejected</MenuItem>
                  </Select>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
    </Layout>
  );
};

export default ViewRequest;