// eslint-disable-next-line
export default (url, body, method) => {
  return new Promise (async (resolve) => {
    const token = localStorage.getItem('token');
    
    const requestOptions = {
      headers : {
        'Content-Type' : 'application/json',
        'x-auth-token' : token ? token : '',
      },
      method : method ? method : (!!body ? 'POST' : 'GET'),
      redirect : 'follow'
    };

    if(body){
      requestOptions.body = JSON.stringify(body);
    };

    /*console.log(
      "URL => ",url,
      " BODY => ",body, ", METHOD => ", requestOptions.method
    );*/

    fetch(url, requestOptions)
    .then(res => res.json())
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      //console.log("Error en",url,error);
      resolve({success: false, error});
    });
  });
};