import React from 'react';
import ReactDOM from 'react-dom';
import 'fontsource-roboto';
import './index.css';

import RootRouter from './routes';

ReactDOM.render(
  <React.Fragment>
    <RootRouter />
  </React.Fragment>,
  document.getElementById('root')
);