import React, { useState } from 'react';
import { makeStyles, Box, FormControl, InputLabel, Input, InputAdornment, IconButton, Button, TextField, colors } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';

import BackgroundImg from '../../components/BackgroundImg';
import CustomSnackbar from '../../components/CustomSnackbar';

import logo from '../../assets/images/logo-medio.png';
import api from '../../values/api';
import request from '../../functions/request';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  container: {
    [theme.breakpoints.up(('sm'))]: {
      width: '50%'
    },
    [theme.breakpoints.up(('md'))]: {
      width: '40%'
    },
    [theme.breakpoints.down(('xs'))]: {
      width: '75%'
    },
  },
  button: {
    marginTop: theme.spacing(4)
  },
  logo: {
    width: theme.spacing(10)
  },
  input: {
    marginTop: theme.spacing(1)
  }
}));

const Login = () => {
  document.title='Bet - Iniciar sessão';
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(email === '' || password === ''){
      setShowMsg({ show: true, text: 'Todos os campos são obrigatórios', severity: 'error' });
      return
    };
    
    const auth = await request(api.auth, {email, password}, 'POST');
    if(auth.success){
      localStorage.setItem('token', auth.token);
      localStorage.setItem('uid', auth.user._id);
      if(auth.user.userType === 'user') history.push(`/${auth.user._id}`);
      if(auth.user.userType === 'admin') {
        localStorage.setItem('userType', auth.user.userType);
        history.push('/admin/dashboard');
      };
    }else {
      setShowMsg({ show: true, text: auth.msg, severity: 'error' });
    };
  };

  return (
    <BackgroundImg>
      <Box className={classes.root}>
        <form className={classes.container} onSubmit={handleSubmit} >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            bgcolor={colors.common.white}
            px={3}
            py={4}
            borderRadius={10}
          >
            <img src={logo} alt="logo-peg" className={classes.logo} />
            <TextField
              id='email-input'
              label='Email'
              type='email'
              fullWidth
              value={email}
              className={classes.input}
              onChange={e => setEmail(e.target.value.trim())}
            />
            <FormControl fullWidth className={classes.input} >
              <InputLabel htmlFor='password-input'>Password</InputLabel>
              <Input
                id='password-input'
                type={showPass ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value.trim())}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle-password-visibility'
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              className={classes.button}
              onClick={handleSubmit}
            >
              Início
            </Button>
          </Box>
        </form>
      </Box>
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
    </BackgroundImg>
  );
};

export default Login;