import axios from 'axios';
import api from '../values/api';

// eslint-disable-next-line
export default (file) => {
  return new Promise ((resolve) => {
    let data = new FormData();
    data.append('file', file);

    const config = {
      headers: {
        'Content-Type' : 'multipart/form-data'
      }
    };

    axios.post(api.imgUpload, data, config)
    .then(res => {
      if(res.data.success) resolve(res.data)
      else resolve(res.data)
    })
    .catch(error => {
      //console.log('error en', api.imgUpload, error);
      resolve({success: false, error});
    });
  });
};