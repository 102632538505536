import React from 'react';

import Layout from '../../components/dashboard/layout/Layout';

const Languages = () => {
  return (
    <Layout>
      
    </Layout>
  );
};

export default Languages;