import React from 'react';
import { AppBar, Toolbar, Typography, makeStyles, IconButton, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  title: {
    marginRight: theme.spacing(2)
  },
  full: {
    flexGrow: 1
  },
  appBar: {
    [theme.breakpoints.up(('sm'))]: {
      width: `calc(100% - 240px)`,
      marginLeft: '240px'
    }
  }
}));

const Navbar = ({onOpen}) => {
  const classes = useStyles();

  return (
    <AppBar position='fixed' color='primary' className={classes.appBar} >
      <Toolbar>
        <Hidden smUp>
          <IconButton
            color='inherit'
            aria-label='menu'
            onClick={onOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.full}></div>
        <Typography variant='h6' className={classes.title}>
          Admin
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;