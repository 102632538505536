import React from 'react';
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CustomSnackbar = ({severity, text, open, setOpen}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen({ show: false, text: '', severity: severity });
  };

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar
