//export const base = 'http://192.168.0.9:4000/';
//export const base = "http://peg.xweb.live/";
export const base = 'https://betoficial-375706.uc.r.appspot.com/';

const api = base + "api/";

// eslint-disable-next-line
export default {
  auth: api + "auth",
  register: api + "user/create",

  imgUpload: api + "image/upload",
  editProfile: api + "user/editProfile",
  editSocial: api + "user/social",
  getAllUsers: api + "user/countUsers",
  searchUser: api + "user/search",
  getUser: api + "user",

  editUser: api + "admin/user/editUser",
  sendPush: api + "admin/notifications/sendMultiple",

  publicity: api + "admin/publicity",
  newPublicity: api + "admin/publicity/new",

  requestAccount: api + "bankAccount",
};
