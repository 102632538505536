import React, { useState } from 'react'
import { makeStyles, Paper, Box, Grid, FormControl, TextField, Typography, Input, InputLabel, InputAdornment, IconButton, Button } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import api from '../../../values/api';
import request from '../../../functions/request';

const useStyles = makeStyles((theme) => ({
  form: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const NewUser = ({set, fetch, setShowMsg}) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  
  const handleSubmit = async () => {
    if(email=== '' || password === '' || name === ''){ 
      setShowMsg({ show: true, text: 'All fields are mandatory', severity: 'error' });
      return
    };
    
    if(password.length < 7){ 
      setShowMsg({ show: true, text: 'Password must be at least 7 characters', severity: 'error' });
      return
    };

    const res = await request(api.register, {name, email, password}, 'POST');

    if(res.success){
      const avatar = "https://mypegoficial.com/uploads/278_perfil-avatar-hombre-icono-redondo_24640-14044.jpg";

      const url = `${api.editUser}/${res.user._id}`;

      const update = await request(url, {avatar, public: true}, 'POST');
      
      if(update.success){
        setShowMsg({ show: true, text: 'Account created', severity: 'success' });
        handleGoBack();
      };

    }else {
      setShowMsg({ show: true, text: 'This email is already in use', severity: 'error' });
    };
  };

  const handleGoBack = () => {
    set(false);
    fetch();
  };

  return (
    <Paper>
      <Box
        pt={2}
        ml={2}
      >
        <Typography variant='h6'>
          New User
        </Typography>
      </Box>
      <FormControl className={classes.form}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id='name-input'
              label='Name'
              fullWidth
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='email-input'
              label='Email'
              type='email'
              fullWidth
              value={email}
              onChange={e => setEmail(e.target.value.trim())}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth >
              <InputLabel htmlFor='password-input'>Password</InputLabel>
              <Input
                id='password-input'
                type={showPass ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value.trim())}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle-password-visibility'
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box
              display='flex'
              justifyContent='space-around'
              my={2}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                Create
              </Button>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => set(false)}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormControl>
    </Paper>
  );
};

export default NewUser;