import React, { useState, useRef } from 'react';
import { makeStyles, Paper, Box, FormControl, InputLabel, TextField, Select, MenuItem, Grid, Typography, Button, Input, InputAdornment, IconButton, Avatar, Hidden } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

import CustomSwitch from '../CustomSwitch';

import api from '../../../values/api';
import request from '../../../functions/request';
import requestFile from '../../../functions/requestFile';

const useStyles = makeStyles((theme) => ({
  form: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  select: {
    minWidth: '100%'
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  button: {
    height: theme.spacing(4)
  },
  icon: {
    maxWidth: theme.spacing(3)
  }
}));

const EditUser = ({user, set, setShowMsg, fetch}) => {
  const classes = useStyles();
  const hiddenFileInput = useRef(null);
  const [userData, setUserData] = useState(user);
  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState('');

  const handleChangeImage = async (e) => {
    hiddenFileInput.current.click()
  };

  const handleChange = async (e) => {
    setShowMsg({ show: true, text: 'Loading file', severity: 'info' });
    const file = e.target.files[0];
    const resUpload = await requestFile(file);

    if(resUpload.success){
      const url = `${api.editUser}/${userData._id}`;
      const update = await request(url, {user: {avatar: resUpload.filepath}}, 'POST');
      
      if(update.success){
        delete update.user.password;

        setUserData(update.user);
        
        setShowMsg({ show: true, text: 'Profile updated successfully', severity: 'success' });
      }else {
        setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
      };
    }else {
      setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    };
  };

  const handleSubmit = async () => {
    if(userData.name === ''){ 
      setShowMsg({ show: true, text: 'The name cannot be empty', severity: 'error' });
      return
    };

    const data = {
      avatar: userData.avatar,
      name: userData.name,
      description: userData.description,
      gender: userData.gender,
      profession: userData.profession,
      dob: userData.dob
    };

    if(password === ''){
      const url = `${api.editUser}/${userData._id}`;
      const update = await request(url, {user: data}, 'POST');

      if(update.success){
        setShowMsg({ show: true, text: 'Profile updated successfully', severity: 'success' });
        handleGoBack();
      }else {
        setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
      };
    }else {
      const url = `${api.editUser}/${userData._id}`;
      const update = await request(url, {user: data, password }, 'POST');

      if(update.success){
        setShowMsg({ show: true, text: 'Profile updated successfully', severity: 'success' });
        handleGoBack();
      }else {
        setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
      };
    }
  };

  const handleGoBack = () => {
    set(false);
    fetch();
  };

  const handleChangePublic = async () => {
    const url = `${api.editUser}/${userData._id}`;
    const updatePublic = await request(url, {user: {public: (!userData.public)}}, 'POST');

    if(updatePublic.success){
      delete updatePublic.user.password;

      setUserData(updatePublic.user);
      
      setShowMsg({ show: true, text: 'Profile updated successfully', severity: 'success' });
    }else {
      setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    };
  };

  return (
    <Paper>
      <Box
        pt={2}
        ml={2}
      >
        <Typography variant='h6'>
          Edit user
        </Typography>
      </Box>
      <FormControl className={classes.form}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='flex-end'
            >
              <Avatar
                src={userData.avatar}
                className={classes.large}
              />
              <Box
                display='flex'
                flexDirection='column'
                ml={2}
              >
                <CustomSwitch
                  label={'Public'}
                  checked={userData.public}
                  handleChange={handleChangePublic}
                />
                <Hidden xsDown>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    startIcon={<EditIcon />}
                    onClick={handleChangeImage}
                  >
                    change avatar
                  </Button>
                </Hidden>
                <Hidden smUp>
                  <IconButton
                    color='primary'
                    aria-label='Edit'
                    onClick={handleChangeImage}
                    className={classes.icon}
                  >
                    <EditIcon />
                  </IconButton>
                </Hidden>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  accept="image/png, image/jpg, image/jpeg"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='name-input'
              label='Name'
              fullWidth={true}
              value={userData.name}
              onChange={e => setUserData({ ...userData, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='email-input'
              label='Email'
              fullWidth={true}
              value={userData.email}
              onChange={e => setUserData({ ...userData, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor='password-input'>Password</InputLabel>
              <Input
                id='password-input'
                type={showPass ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value.trim())}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='description-input'
              label='Description'
              fullWidth={true}
              value={userData.description}
              onChange={e => setUserData({ ...userData, description: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='profession-input'
              label='Profession'
              fullWidth={true}
              value={userData.profession}
              onChange={e => setUserData({ ...userData, profession: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.select}>
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                id="gender-select"
                value={userData.gender ? ((userData.gender === 'male' ||userData.gender === 'female') ? userData.gender : '') : ''}
                onChange={e => setUserData({ ...userData, gender: e.target.value })}
                >
                <MenuItem value={''}>Select a gender</MenuItem>
                <MenuItem value={'male'}>Homen</MenuItem>
                <MenuItem value={'female'}>Mulher</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="date"
              label="Birthday"
              type="date"
              defaultValue={moment(userData.dob).format('YYYY-MM-DD')}
              className={classes.select}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display='flex'
              justifyContent='space-around'
              my={2}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleGoBack}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormControl>
    </Paper>
  );
};

export default EditUser;