import React, { useState } from 'react';
import { makeStyles, Paper, Box, Typography, TextField, Button, TableContainer, Table, TableHead, TableRow, withStyles, TableCell, TableBody, colors, Hidden, IconButton, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import api from '../../../values/api';
import request from '../../../functions/request';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    [theme.breakpoints.down(('xs'))]: {
      width: '150px'
    },
    [theme.breakpoints.up(('md'))]: {
      width: '350px'
    },
  },
  statusGreen: {
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(1),
    background: colors.green[500],
    '&:hover': {
      backgroundColor: colors.green[600],
    },
  },
  flex: {
    display: 'flex'
  },
  icon: {
    marginRight: '2px'
  }
}));

const TableUser = ({list, setList, set, show, showNew, loading, fetch, setShowMsg}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});

  const handleSet = (user) => {
    set(user);
    show(true)
  };

  const handleOpenDelete = (user) => {
    setOpen(true);
    setUser(user);
  };

  const handleCloseDelete = () => {
    setOpen(false);
    setUser({});
  };

  const handleDelete = async () => {
    const url = `${api.auth}/${user._id}`;
    const res = await request(url, null, 'DELETE');
    if(res.success){
      handleCloseDelete();
      fetch();
      setShowMsg({ show: true, text: 'User deleted correctly', severity: 'success' });
    }else {
      handleCloseDelete();
      setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    };
  };

  const handleStatus = async (user) => {
    if(user.status === 'active'){
      const url = `${api.editUser}/${user._id}`;
      const res = await request(url, {user: {status: 'disable'}}, 'POST');
      
      if(res.success){
        const data = [...list];
        data.map((el, i) => {
          if(el._id === user._id){
            el.status = 'disable'
          };
          return null
        });
        setList(data);
      }else setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    }else {
      const url = `${api.editUser}/${user._id}`;
      const res = await request(url, {user: {status: 'active'}}, 'POST');
      
      if(res.success){
        const data = [...list];
        data.map((el, i) => {
          if(el._id === user._id){
            el.status = 'active'
          };
          return null
        });
        setList(data);
      }else setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    };
  };

  return (
    <Paper>
      <Box
        p={1}
      >
        <Typography variant='h6'>
          List users
        </Typography>
        <Box
          display='flex'
          justifyContent='space-between'
          px={1}
          mt={1}
        >
          <TextField
            id='search-input'
            label='User name or email'
            className={classes.input}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            startIcon={<AccountCircleIcon />}
            onClick={() => showNew(true)}
          >
            New user
          </Button>
        </Box>
        {loading &&
          <Box
            display='flex'
            justifyContent='center'
            py={2}
          >
            <CircularProgress />
          </Box>
        }
        {!loading &&
          <TableContainer component={'div'}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Users</StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((user, i) => ((user.name.toLowerCase().includes(search) || user.email.toLowerCase().includes(search)) && 
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      <Box
                        display='flex'
                        alignItems='center'
                      >
                        <Box className={classes.icon}>
                          <AccountCircleIcon />
                        </Box>
                        {user.name}
                      </Box>
                      <Box
                        display='flex'
                        alignItems='center'
                      >
                        <Box className={classes.icon}>
                          <EmailIcon />
                        </Box>
                        {user.email}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Hidden xsDown>
                        <Button
                          variant='contained'
                          color={user.status === 'active' ? 'primary' : 'secondary'}
                          className={classes.button}
                          startIcon={<AccountCircleIcon />}
                          onClick={() => handleStatus(user)}
                        >
                          {user.status === 'active' ? 'Active' : 'Locked'}
                        </Button>
                        <Button
                          variant='contained'
                          className={classes.statusGreen}
                          startIcon={<EditIcon />}
                          onClick={() => handleSet(user)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant='contained'
                          color='secondary'
                          className={classes.button}
                          startIcon={<DeleteIcon />}
                          onClick={() => handleOpenDelete(user)}
                        >
                          Delete
                        </Button>
                      </Hidden>
                      <Hidden smUp>
                        <Box
                          display='flex'
                        >
                          <IconButton
                            color={user.status === 'active' ? 'primary' : 'secondary'}
                            aria-label='AccountCircle'
                            onClick={() => handleStatus(user)}
                          >
                            <AccountCircleIcon />
                          </IconButton>
                          <IconButton
                            color='primary'
                            aria-label='Edit'
                            onClick={() => handleSet(user)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color='secondary'
                            aria-label='Delete'
                            onClick={() => handleOpenDelete(user)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Hidden>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <Dialog
          open={open}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure to delete user?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              After delete user can't return this operation!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
};

export default TableUser;

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: theme.palette.common.black,
    //color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);