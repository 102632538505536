import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider, makeStyles, Drawer, Typography, Box } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TouchAppIcon from '@material-ui/icons/TouchApp';
//import FontDownloadIcon from '@material-ui/icons/FontDownload';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import MessageIcon from '@material-ui/icons/Message';
import { withRouter } from 'react-router-dom';

const widthDrawer = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: widthDrawer,
    flexShrink: 0,
  },
  drawerPaper: {
    width: widthDrawer,
    //backgroundColor: theme.palette.primary.main,
    //color: 'white'
  },
  toolbar: theme.mixins.toolbar
}));

const Sidebar = ({variant, open, onClose, ModalProps, history}) => {
  const classes = useStyles();

  const handleLogout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    localStorage.removeItem('userType');
    history.push('/auth');
  };

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor='left'
      variant={variant}
      open={open}
      onClose={onClose ? onClose : null}
      ModalProps={{
        keepMounted: ModalProps ? true : null
      }}
    >
      <div className={classes.toolbar} >
        <Box
          className={classes.toolbar}
          display='flex'
          color='primary.contrastText'
          bgcolor='primary.main'
          alignItems='center'
          justifyContent='center'
        >
          <Typography variant='h6'>
            BET
          </Typography>
        </Box>
      </div>
      <Divider />
      <List component='nav'>
        <ListItem button onClick={() => history.push('/admin/dashboard')}>
          <ListItemIcon>
            <BarChartIcon/>
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => history.push('/admin/users')}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary='Account Users' />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => history.push('/admin/notification')}>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary='Notifications' />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => history.push('/admin/publicity')}>
          <ListItemIcon>
            <TouchAppIcon />
          </ListItemIcon>
          <ListItemText primary='Publicity' />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => history.push('/admin/request')}>
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText primary='Request' />
        </ListItem>
        {/*<Divider />
        <ListItem button onClick={() => history.push('/admin/languages')}>
          <ListItemIcon>
            <FontDownloadIcon />
          </ListItemIcon>
          <ListItemText primary='Languages' />
        </ListItem>*/}
        <Divider />
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <DirectionsRunIcon />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItem>
        <Divider />
      </List>
    </Drawer>
  );
};

export default withRouter(Sidebar);