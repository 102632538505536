import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Button, colors, CircularProgress, Typography, Avatar, Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment'

import BackgroundImg from '../../components/BackgroundImg';
import CustomSnackbar from '../../components/CustomSnackbar';
import SocialIcon from '../../components/SocialIcon';

import api from '../../values/api';
import request from '../../functions/request';

import iconSF from '../../assets/icons/Spotify.png';
import iconDB from '../../assets/icons/Dropbox.png';
import iconEN from '../../assets/icons/Evernote.png';
import iconFB from '../../assets/icons/Facebook.png';
import iconGG from '../../assets/icons/Google.png';
import iconIG from '../../assets/icons/Instagram.png';
import iconLKI from '../../assets/icons/LinkedIn.png';
import iconSC from '../../assets/icons/Snapchat.png';
import iconSk from '../../assets/icons/Skipe.png';
import iconTW from '../../assets/icons/Twitter.png';
import iconVM from '../../assets/icons/Vimeo.png';
import iconYT from '../../assets/icons/Youtube.png';
import iconWS from '../../assets/icons/Whatsapp.png';
import iconPP from '../../assets/icons/Paypal.png';
import iconWB from '../../assets/icons/Website.png';
import iconFL from '../../assets/icons/Link.png';
import iconLOC from '../../assets/icons/Location.png';
import iconTT from '../../assets/icons/Tiktok.png';

const SOCIALS = [
  {name: 'facebook', icon: iconFB},
  {name: 'instagram', icon: iconIG},
  {name: 'twitter', icon: iconTW},
  {name: 'google', icon: iconGG},
  {name: 'linkedIn', icon: iconLKI},
  {name: 'phone', icon: iconWS},
  {name: 'youtube', icon: iconYT},
  {name: 'spotify', icon: iconSF},
  {name: 'snapchat', icon: iconSC},
  {name: 'tiktok', icon: iconTT},
  {name: 'vimeo', icon: iconVM},
  {name: 'dropbox', icon: iconDB},
  {name: 'evernote', icon: iconEN},
  {name: 'skype', icon: iconSk},
  {name: 'paypal', icon: iconPP},
  {name: 'file', icon: iconFL},
  {name: 'website', icon: iconWB},
  {name: 'location', icon: iconLOC},
];

const useStyles = makeStyles(theme => ({
  buttonDark: {
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(1),
    background: colors.common.black,
    '&:hover': {
      backgroundColor: colors.grey[900],
    },
  },
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    marginBottom: theme.spacing(1)
  },
  spinner: {
    color: theme.palette.common.white
  },
}));

const Profile = () => {
  document.title= 'Bet - Perfil';
  const { id, username } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [userProfile, setUserProfile] = useState(null);
  const [auth, setAuth] = useState({loggedIn: false, uid: '', token: ''});
  const [loading, setLoading] = useState(true);
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });
  
  useEffect(() => {
    initFetch();
    // eslint-disable-next-line
  }, []);

  const initFetch = async () => {
    setLoading(true);

    if(!id && !username){
      setLoading(false);
      return
    };

    const uid = localStorage.getItem('uid');
    const token = localStorage.getItem('token');
    if(uid && token){
      setAuth({...auth, loggedIn: true, uid, token});
    };

    const url = username ? `${api.getUser}/search?username=${username}` : `${api.getUser}/search?_id=${id}`;
    const user = await request(url, null, 'GET');
    
    if(user.success){
      setUserProfile(user.user);
      setLoading(false);
    }else {
      setShowMsg({ show: true, text: 'Bet Perfil não encontrado', severity: 'error' });
      setLoading(false);
    };
  };
  
  const handleClick = () => {
    history.push(`/${id}/edit`);
  };
  
  const handleSigout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    setAuth({loggedIn:false, token: '', uid: ''});
  };
  
  const socialList = [];
  if(userProfile && userProfile.social){
    for (const element in userProfile.social){
      if(element.length > 3 && userProfile.social[element] !== ''){
        SOCIALS.map(el => {
          if(el.name === element) socialList.push({[element]: userProfile.social[element], icon: el.icon});
          return null
        });
      };
    };
  };
    
  return (
    <BackgroundImg>
      {loading && 
        <Box
          display='flex'
          justifyContent='center'
          py={4}
        >
          <CircularProgress className={classes.spinner} size={50} />
        </Box>
      }
      {((!id && !username && !loading) || (!userProfile && !loading)) &&
        <Box
          display='flex'
          justifyContent='center'
          mt={4}
          color={colors.common.white}
        >
          <Typography variant='h4'>
            Bet Perfil não encontrado
          </Typography>
        </Box>
      }
      {(!loading && userProfile) &&
        <Box
          py={3}
          display='flex'
          flexDirection='column'
          alignItems='center'
          color={colors.common.white}
        >
          <Typography variant='h4'>
            Bet Perfil
          </Typography>
          <Box
            my={1}
          >
            {auth.loggedIn ?
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonDark}
                onClick={handleSigout}
              >
                Fechar sessão
              </Button> :
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonDark}
                onClick={() => history.push('/auth')}
              >
                Iniciar sessão
              </Button>
            }
            {(auth.loggedIn && auth.uid === id) &&
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonDark}
                onClick={handleClick}
              >
                Editar perfil
              </Button>
            }
          </Box>
          <Avatar
            src={userProfile.avatar}
            alt={userProfile.name}
            className={classes.avatar}
          />
          <Typography variant='body1'>
            Nome completo: {userProfile.name}
          </Typography>

          {userProfile.description && 
            <Typography variant='body1'>
              Descrição/Bio: {userProfile.description}
            </Typography>
          }

          {(userProfile.gender === 'male' ||userProfile.gender === 'female') && 
            <>
              <Typography variant='body1'>
                Sexo ou gênero: {userProfile.gender === 'male' ? 'Homem' : 'Mulher'}
              </Typography>
            </>
          }

          {userProfile.profession && 
            <Typography variant='body1'>
              Profissão: {userProfile.profession}
            </Typography>
          }

          {userProfile.dob && 
            <Typography variant='body1'>
              Data de nascimento: {moment(userProfile.dob).format('DD-MM-YYYY')}
            </Typography>
          }
          {(userProfile.social && userProfile.public) &&
            <Grid
              container
            >
              {socialList.map((item, i) => <SocialIcon item={item} key={i} />)}  
            </Grid>
          }
        </Box>
      }
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
    </BackgroundImg>
  );
};

export default Profile
