import React from 'react';
import { makeStyles, Modal, Grid, Typography, Input, Button, colors } from '@material-ui/core';

import api from '../values/api';
import request from '../functions/request';

import iconSF from '../assets/icons/Spotify.png';
import iconDB from '../assets/icons/Dropbox.png';
import iconEN from '../assets/icons/Evernote.png';
import iconFB from '../assets/icons/Facebook.png';
import iconGG from '../assets/icons/Google.png';
import iconIG from '../assets/icons/Instagram.png';
import iconLKI from '../assets/icons/LinkedIn.png';
import iconSC from '../assets/icons/Snapchat.png';
import iconSk from '../assets/icons/Skipe.png';
import iconTW from '../assets/icons/Twitter.png';
import iconVM from '../assets/icons/Vimeo.png';
import iconYT from '../assets/icons/Youtube.png';
import iconWS from '../assets/icons/Whatsapp.png';
import iconPP from '../assets/icons/Paypal.png';
import iconWB from '../assets/icons/Website.png';
import iconFL from '../assets/icons/Link.png';
import iconLOC from '../assets/icons/Location.png';
import iconTT from '../assets/icons/Tiktok.png';

const SOCIALS = [
  {name: 'facebook', icon: iconFB},
  {name: 'instagram', icon: iconIG},
  {name: 'twitter', icon: iconTW},
  {name: 'google', icon: iconGG},
  {name: 'linkedIn', icon: iconLKI},
  {name: 'phone', icon: iconWS},
  {name: 'youtube', icon: iconYT},
  {name: 'spotify', icon: iconSF},
  {name: 'snapchat', icon: iconSC},
  {name: 'tiktok', icon: iconTT},
  {name: 'vimeo', icon: iconVM},
  {name: 'dropbox', icon: iconDB},
  {name: 'evernote', icon: iconEN},
  {name: 'skype', icon: iconSk},
  {name: 'paypal', icon: iconPP},
  {name: 'file', icon: iconFL},
  {name: 'website', icon: iconWB},
  {name: 'location', icon: iconLOC},
];

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 1),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up(('sm'))]: {
      width: '80%'
    },
    [theme.breakpoints.down(('xs'))]: {
      width: '80%'
    },
    overflowY:'scroll',
    maxHeight: '90vh'
  },
  avatar: {
    height: '50px',
    width: '50px',
    marginRight: theme.spacing(1)
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonDark: {
    color: theme.palette.primary.contrastText,
    background: colors.common.black,
    '&:hover': {
      backgroundColor: colors.grey[900],
    },
  },
}));

const SocialMedia = ({ open, handleOpen, profile, setProfile, setShowMsg}) => {
  const classes = useStyles();

  const handleUpdate = async () => {
    const resUpdate = await request(api.editSocial, profile.social, 'POST');

    if(resUpdate.success){
      setShowMsg({ show: true, text: 'Perfil atualizado com sucesso', severity: 'success' });
      handleOpen();
    }else {
      setShowMsg({ show: true, text: 'o nome não pode estar vazio', severity: 'error' });
    };
  };

  return (
    <Modal
      open={open}
      onClose={handleOpen}
      aria-labelledby="modal-social-media"
      aria-describedby="modal-social-media"
    >
      <Grid
        container
        spacing={2}
        className={classes.paper}
      >
        <Grid item xs={12}>
          <Typography variant='h6'>
            Redes sociais:
          </Typography>
        </Grid>
        {SOCIALS.map((element, i) => 
          <Grid
            item
            sm={6}
            xs={12}
            key={i}
            className={classes.content}
          >
            <img
              src={element.icon}
              alt={element.name}
              className={classes.avatar}
            />
            <Input
              id={`input-${element.name}`}
              type='text'
              fullWidth
              value={profile.social && profile.social[`${element.name}`] ? profile.social[`${element.name}`] : ''}
              onChange={e => setProfile({...profile, social: {...profile.social, [`${element.name}`]: e.target.value }})}
            />
          </Grid>
        )}
        <Grid item sm={6} xs={12} className={classes.content}>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonDark}
            onClick={handleUpdate}
          >
            Salvar
          </Button>
        </Grid>
        <Grid item sm={6} xs={12} className={classes.content}>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonDark}
            onClick={handleOpen}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SocialMedia;