import React from 'react';
import { makeStyles } from '@material-ui/core';

import bg from '../assets/images/background.jpeg'

const useStyles = makeStyles(theme => ({
  root:{
    backgroundImage: 'url('+bg+')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh',
    overflow: 'auto'
  }
}));

const BackgroundImg = ({children}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default BackgroundImg;