import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Box } from '@material-ui/core';
import moment from 'moment';

import Layout from '../../components/dashboard/layout/Layout';
import CardInfo from '../../components/dashboard/CardInfo';
import Graph from '../../components/dashboard/Graph';
import CustomSnackbar from '../../components/CustomSnackbar';

import request from '../../functions/request';
import api from '../../values/api';

const Dashboard = () => {
  document.title='Bet - Dashboard';
  const [graph, setGraph] = useState({});
  const [totalYear, setTotalYear] = useState('');
  const [total, setTotal] = useState('');
  const [loading, setLoading] = useState(true);
  const [yearList, setYearList] = useState(moment(new Date()).format('YYYY'));
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });
  
  useEffect(() => {
    initFetch();
    // eslint-disable-next-line
  }, [yearList]);

  const initFetch = async () => {
    setLoading(true);
    const url = `${api.getAllUsers}?year=${yearList}`;
    const res1 = await request(api.getUser);
    const res2 = await request(url, null, 'GET');
    console.log(res1);
    const data = [];
    for (const element in res2.months) data.push({users: res2.months[element].value, month: res2.months[element].id});

    if(res2.success){
      setTotal(res1.user.length);
      setTotalYear(res2.total);
      setGraph(data);
      setLoading(false);
    }else {
      setShowMsg({ show: true, text: 'Error cannot load', severity: 'error' });
      setLoading(false);
    };
  };

  return (
    <Layout>
      {loading && 
        <Box
          display='flex'
          justifyContent='center'
          py={2}
        >
          <CircularProgress />
        </Box>
      }
      {!loading &&
      <div>
        <Grid container spacing={1}>
          <CardInfo text={`Total registered users: ${totalYear}`} />
          <CardInfo text={`Total users: ${total}`} />
        </Grid>
        <Grid container spacing={1} alignContent='center' justify='center'>
          <Grid item xs={12} sm={11} >
            <Graph data={graph} height={250} year={yearList} setYear={setYearList} xLabel={'month'} yLabel={'users'} />
          </Grid>
        </Grid>
      </div>
      }
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
    </Layout>
  );
};

export default Dashboard;