import React, { useState, useRef } from 'react';
import { makeStyles, Box, FormControl, Grid, Paper, Typography, TextField, IconButton, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import Layout from '../../components/dashboard/layout/Layout';
import CustomSnackbar from '../../components/CustomSnackbar';

import api from '../../values/api'
import request from '../../functions/request';
import requestFile from '../../functions/requestFile';
import preview from '../../assets/images/logo-medio.png';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  icon: {
    width: '175px',
    height: '175px'
  },
  input: {
    marginTop: theme.spacing(2),
  },
}));

const Notifications = () => {
  document.title='Bet - Send Notifications';
  const classes = useStyles();
  const hiddenFileInput = useRef(null);
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [enlace, setEnlace] = useState('');
  const [image, setImage] = useState('');
  
  const handleChangeImage = async (e) => {
    hiddenFileInput.current.click()
  };

  const handleChange = async (e) => {
    setShowMsg({ show: true, text: 'Loading file', severity: 'info' });
    const file = e.target.files[0];
    const resUpload = await requestFile(file);

    if(resUpload.success){
      setImage(resUpload.filepath);
    }else {
      setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    };
  };

  const handleSubmit = async () => {
    if(title === '' || body === '') return setShowMsg({ show: true, text: 'Title or body cannot be empty', severity: 'error' });

    const sendPush = await request(api.sendPush, {title, body, url: enlace, image}, 'POST');

    if(sendPush.success){
      setShowMsg({ show: true, text: 'Notification sent successfully', severity: 'success' });
    }else {
      setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    };
  };

  return (
    <Layout>
      <Paper>
        <Box
          p={2}
        >
          <Typography variant='h6'>
            Send Notifications
          </Typography>
          <FormControl className={classes.form}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                >
                  <Box
                    display='flex'
                    alignItems='flex-end'
                  >
                    <img
                      src={image ? image : preview}
                      alt='img-notification'
                      className={classes.icon}
                    />
                    <IconButton
                      color='primary'
                      aria-label='Edit'
                      onClick={handleChangeImage}
                    >
                      <EditIcon />
                    </IconButton>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: 'none' }}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </Box>
                  <TextField
                    id='img-url-input'
                    label='URL Image (optional)'
                    variant='outlined'
                    fullWidth
                    className={classes.input}
                    value={image}
                    onChange={e => setImage(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.content}
              >
                <TextField
                  id='title-input'
                  label='Title'
                  variant='outlined'
                  fullWidth
                  className={classes.input}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
                <TextField
                  id='body-input'
                  label='Body'
                  variant='outlined'
                  fullWidth
                  multiline
                  rows={2}
                  className={classes.input}
                  value={body}
                  onChange={e => setBody(e.target.value)}
                />
                <TextField
                  id='url-input'
                  label='URL (optional)'
                  variant='outlined'
                  fullWidth
                  className={classes.input}
                  value={enlace}
                  onChange={e => setEnlace(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  display='flex'
                  justifyContent='center'
                  my={1}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit}
                  >
                    send notification
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </Paper>
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
    </Layout>
  );
};

export default Notifications;