import React, { useState, useEffect } from 'react';

import Layout from '../../components/dashboard/layout/Layout';
import TableUser from '../../components/dashboard/user/TableUser';
import EditUser from '../../components/dashboard/user/EditUser';
import NewUser from '../../components/dashboard/user/NewUser';
import CustomSnackbar from '../../components/CustomSnackbar';

import request from '../../functions/request';
import api from '../../values/api';

const Users = () => {
  document.title='Bet - Account users';
  const [userList, setUserList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });

  useEffect(() => {
    initFetch();
  }, []);

  const initFetch = async () => {
    setLoading(true)
    const res = await request(api.getUser, null, 'GET');

    if(res.success){
      setUserList(res.user);
      setLoading(false);
    }else {
      setShowMsg({ show: true, text: 'Error cannot load', severity: 'error' });
      setLoading(false);
    };
  };

  return (
    <Layout>
      {!(showEdit || showNew) && 
        <TableUser
          list={userList}
          setList={setUserList}
          set={setUser}
          show={setShowEdit}
          showNew={setShowNew}
          loading={loading}
          setShowMsg={setShowMsg}
          fetch={initFetch}
        />
      }
      {showEdit && <EditUser user={user} set={setShowEdit} setShowMsg={setShowMsg} fetch={initFetch} /> }
      {showNew && <NewUser set={setShowNew} fetch={initFetch} setShowMsg={setShowMsg} /> }
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
    </Layout>
  );
};

export default Users;