import React, { useState } from 'react';
import { makeStyles, Hidden } from '@material-ui/core';

import Navbar from './Navbar';
import Sidebar from './Sidebar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  }
}))

const Layout = ({children}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.root}>
      <Navbar
        onOpen={() => setOpen(!open)}
      />
      <Hidden xsDown>
        <Sidebar
          variant={'permanent'}
          open={true}
        />
      </Hidden>
      <Hidden smUp>
        <Sidebar
          variant={'temporary'}
          open={open}
          onClose={() => setOpen(!open)}
          ModalProps
        />
      </Hidden>
      <div className={classes.content}>
        <div className={classes.toolbar}></div>
        {children}
      </div>
    </div>
  );
};

export default Layout;