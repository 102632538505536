import React from 'react';
import { Box, Grid, colors, Typography } from '@material-ui/core';

const CardInfo = ({text}) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        color='primary.contrastText'
        bgcolor={colors.green['A400']}
        p={2}
        borderRadius={15}
      >
        <Typography>
          {text}
        </Typography>
      </Box>
    </Grid>
  );
};

export default CardInfo;