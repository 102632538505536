import React from 'react';
import { makeStyles, Box, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
  },
  icon: {
    height: '50px',
    width: '50px'
  },
  text: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    marginTop: theme.spacing(1)
  }
}));

const SocialIcon = ({item}) => {
  const classes = useStyles();
  
  let url = '';

  switch (Object.keys(item)[0]) {
    case 'facebook':
      url = `https://www.facebook.com/${item[Object.keys(item)[0]]}`;
      break;

    case 'instagram':
      url = `https://www.instagram.com/${item[Object.keys(item)[0]]}`;
      break;

    case 'linkedIn':
      url = `https://www.linkedin.com/in/${item[Object.keys(item)[0]]}`;
      break;

    case 'youtube':
      url = `https://www.youtube.com/channel/${item[Object.keys(item)[0]]}`;
      break;

    case 'phone':
      url = `https://api.whatsapp.com/send?phone=${item[Object.keys(item)[0]]}`;
      break;

    case 'tiktok':
      url = `https://vm.tiktok.com/${item[Object.keys(item)[0]]}`;
      break;

    case 'twitter':
      url = `https://twitter.com/${item[Object.keys(item)[0]]}`;
      break;
    
    case 'snapchat':
      url = `https://www.snapchat.com/add/${item[Object.keys(item)[0]]}`;
      break;

    case 'google':
      url = `https://myaccount.google.com/${item[Object.keys(item)[0]]}`;
      break;

    case 'spotify':
      url = `https://open.spotify.com/user/${item[Object.keys(item)[0]]}`;
      break;

    case 'vimeo':
      url = `https://vimeo.com/user/${item[Object.keys(item)[0]]}`;
      break;
    
    case 'skype':
      url = `https://join.skype.com/${item[Object.keys(item)[0]]}`;
      break;

    case 'evernote':
      url = `https://www.evernote.com/${item[Object.keys(item)[0]]}`;
      break;


    default:
      url = item[Object.keys(item)[0]].includes('https://') ? item[Object.keys(item)[0]] : `https://${item[Object.keys(item)[0]]}`;
      break;
  };

  return (
    <Grid item md={2} sm={4} xs={6}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        py={1}
        mt={2}
      >
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.content}
        >
          <img
            src={item.icon}
            alt="social-icon"
            className={classes.icon}
          />
          <Typography
            variant='body2'
            className={classes.text}
          >
            {Object.keys(item)[0] === 'phone' ? 'whatsapp' : Object.keys(item)[0]}
          </Typography>
        </a>
      </Box>
    </Grid>
  );
};

export default SocialIcon;