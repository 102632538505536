import React, { useState, useEffect } from 'react';
import { makeStyles, colors, Box, Grid, Paper, Typography, TextField, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

import Layout from '../../components/dashboard/layout/Layout';
import CustomSnackbar from '../../components/CustomSnackbar';
import CustomSwitch from '../../components/dashboard/CustomSwitch';
import NewPublicity from '../../components/dashboard/publicity/NewPublicity';
import EditPublicity from '../../components/dashboard/publicity/EditPublicity';

import api from '../../values/api';
import request from '../../functions/request';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    [theme.breakpoints.down(('xs'))]: {
      width: '150px'
    },
    [theme.breakpoints.up(('md'))]: {
      width: '350px'
    },
  },
  statusGreen: {
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(1),
    background: colors.green[500],
    '&:hover': {
      backgroundColor: colors.green[600],
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    marginRight: '2px'
  }
}));

const ConfigureAd = () => {
  document.title='Bet - Publicity';
  const classes = useStyles();
  const [publiList, setPubliList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [dialog, setDialog] = useState(false);
  const [publi, setPubli] = useState({});
  const [showMsg, setShowMsg] = useState({ show: false, text: '', severity: 'error' });
  const [showEdit, setShowEdit] = useState(false);
  const [showNew, setShowNew] = useState(false);

  useEffect(() => {
    initFetch();
    // eslint-disable-next-line
  }, []);

  const initFetch = async () => {
    setLoading(true);
    const url = `${api.publicity}/all`;
    const publi = await request(url);
    if(publi.success){
      setPubliList(publi.publicity);
    }else {
      //initFetch();
      setShowMsg({ show: true, text: 'Error cannot load', severity: 'error' });
    };
    setLoading(false);
  };

  const handleCloseDialog = () => {
    setDialog(false);
    setPubli({});
  };

  const handleOpenDialog = (item) => {
    setDialog(true);
    setPubli(item);
  };

  const handleDelete = async () => {
    const url = `${api.publicity}/${publi._id}`;
    const res = await request(url, null, 'DELETE');
    handleCloseDialog();
    if(res.success){
      initFetch();
    }else {
      setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    };
  };

  const handleCancel = () => {
    setPubli({});
    setShowNew(false);
    setShowEdit(false);
  };

  const handleShowEdit = (item) => {
    setPubli(item);
    setShowEdit(true);
  };

  const handleChangeStatus = async (item) => {
    let resStatus = '';
    
    if(item.status === 'enabled') {
      resStatus = 'disabled'
    }else {
      resStatus = 'enabled'
    };

    if(resStatus === '') return setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });

    const update = await request(api.publicity, {status: resStatus, id: item._id}, 'PUT');

    if(update.success){
      initFetch();
    }else {
      setShowMsg({ show: true, text: 'Error', severity: 'error' });
    }
  };

  return (
    <Layout>
      {!(showEdit || showNew) &&
        <Paper>
        <Box
          p={2}
        >
          <Typography variant='h6'>
            Configure Publicity
          </Typography>
          <Box
            display='flex'
            justifyContent='space-between'
            px={1}
            mb={1}
          >
            <TextField
              id='search-input'
              label='Publicity name'
              className={classes.input}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={() => setShowNew(true)}
            >
              New Publicity
            </Button>
          </Box>
          {loading &&
            <Box
              display='flex'
              justifyContent='center'
              py={2}
            >
              <CircularProgress />
            </Box>
          }
          <Grid container spacing={1}>
            {publiList.map((item) => (item.name.toLowerCase().includes(search) &&
              <Grid
                key={item._id}
                item
                xs={12}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Typography variant='body1'>
                    {item.name}
                  </Typography>
                  <Box>
                    <CustomSwitch
                      label={item.status === 'enabled' ? 'Activate' : 'Disable' }
                      checked={item.status === 'enabled' ? true : false}
                      handleChange={() => handleChangeStatus(item)}
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      onClick={() => handleShowEdit(item)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant='contained'
                      color='secondary'
                      className={classes.button}
                      onClick={() => handleOpenDialog(item)}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
      }

      {showNew && <NewPublicity onCancel={handleCancel} fetch={initFetch} setShowMsg={setShowMsg} />}

      {showEdit && <EditPublicity onCancel={handleCancel} publi={publi} fetch={initFetch} setShowMsg={setShowMsg} />}

      <Dialog
        open={dialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure to delete user?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            After delete user can't return this operation!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar severity={showMsg.severity} text={showMsg.text} open={showMsg.show} setOpen={setShowMsg} />
    </Layout>
  );
};

export default ConfigureAd;