import React, { useState } from 'react';
import { makeStyles, Box, Grid, Paper, Typography, TextField, Button } from '@material-ui/core';

import api from '../../../values/api';
import request from '../../../functions/request';
import preview from '../../../assets/images/sneak-preview.jpg';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    width: '100%',
    maxWidth: '300px'
  },
  imgContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}));

const NewPublicity = ({onCancel, fetch, setShowMsg}) => {
  const classes = useStyles();
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [enlace, setEnlace] = useState('');

  const handleSubmit = async () => {
    if(image === '' || title === '' || enlace === '') return setShowMsg({ show: true, text: 'All fields are mandatory', severity: 'error' });

    const res = await request(api.newPublicity, {name: title, slug: title, url: enlace, image: image, status: 'enabled'}, 'POST');
    if(res.success){
      onCancel();
      fetch();
    }else {
      setShowMsg({ show: true, text: 'Something went bad', severity: 'error' });
    };
  };

  return (
    <Paper>
      <Box
        p={2}
      >
        <Typography variant='h6'>
          Create a new Publicity
        </Typography>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Box
              p={1}
              className={classes.imgContent}
            >
              <img
                src={image ? image : preview}
                alt='img-notification'
                className={classes.icon}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.content}
          >
            <TextField
              id='name-input'
              label='Name'
              variant='outlined'
              fullWidth
              className={classes.input}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <TextField
              id='URL-image-input'
              label='URL image'
              variant='outlined'
              fullWidth
              className={classes.input}
              value={image}
              onChange={e => setImage(e.target.value)}
            />
            <TextField
              id='url-input'
              label='URL'
              variant='outlined'
              fullWidth
              className={classes.input}
              value={enlace}
              onChange={e => setEnlace(e.target.value)}
            />
            <Box
              mt={2}
              display='flex'
              alignItems='center'
              justifyContent='space-around'
            >
              <Button
                variant='contained'
                color='secondary'
                onClick={onCancel}
              >
                cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                create
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default NewPublicity;