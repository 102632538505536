import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import Privacy from '../pages/Privacy';
import Profile from '../pages/Profiles/Profile';
import EditProfile from '../pages/Profiles/EditProfile';
import Dashboard from '../pages/Admin/Dashboard';
import Users from '../pages/Admin/Users';
import Notifications from '../pages/Admin/Notifications';
import ConfigureAd from '../pages/Admin/ConfigureAd';
import Languages from '../pages/Admin/Languages';
import ViewRequest from '../pages/Admin/ViewRequest';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path='/auth'                        component={Login}                                  />
        <Route path='/register'                    component={Register}                               />
        <Route path='/privacy'                     component={Privacy}                                />
        <AdminRoute path='/admin/dashboard'        component={Dashboard}                              />
        <AdminRoute path='/admin/users'            component={Users}                                  />
        <AdminRoute path='/admin/notification'     component={Notifications}                          />
        <AdminRoute path='/admin/publicity'        component={ConfigureAd}                            />
        <AdminRoute path='/admin/request'          component={ViewRequest}                            />
        <AdminRoute path='/admin/languages'        component={Languages}                              />
        <PrivateRoute path='/:id/edit'             component={EditProfile}                            />
        <Route path='/user/:username'        exact  component={Profile}                                />
        <Route path='/:id'                  exact  component={Profile}                                />
        <Route path='*'                            component={() => <Redirect to='/auth' />}          />
      </Switch>
    </Router>
  );
};

export default App;

const PrivateRoute = ({ component: Component, computedMatch, ...rest }) => {
  let auth;
  const init = () => {
    const token = localStorage.getItem('token');
    const uid = localStorage.getItem('uid');
    if(token && (uid === computedMatch.params.id)) return auth = true
    else return auth = false
  };

  init();

  return(
    <Route
      {...rest}
      render={(props) => auth ?
        <Component {...props} /> :
        <Redirect to={{ pathname: '/auth', state: { from: props.location }}}/>
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  let auth;
  const init = () => {
    const token = localStorage.getItem('token');
    const userType = localStorage.getItem('userType');
    if(token && userType === 'admin') return auth = true
    else return auth = false
  };

  init();

  return(
    <Route
      {...rest}
      render={(props) => auth ?
        <Component {...props} /> :
        <Redirect to={{ pathname: '/auth', state: { from: props.location }}}/>
      }
    />
  );
};
