import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography, FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const useStyles = makeStyles (theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const Graph = ({data, height, year, setYear, xLabel, yLabel}) => {
  const classes = useStyles();
  const [list, setList] = useState([]);

  useEffect(() => {
    const data = [];
    for (let i=2021; i <= moment().format('YYYY'); i++){
      data.push(i);
    };
    setList(data);
  }, []);

  return (
    <Paper>
      <Box
        my={1}
        minHeight={height}
      >
        <div className={classes.container}>
          <Typography variant='h6'>
            {`Total registered users for ${year}`}
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              labelId="year-label"
              id="year-select"
              value={year}
              onChange={e => setYear(e.target.value)}
            >
              {list.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <ResponsiveContainer width='100%' height='100%' minHeight={height}>
          <LineChart
            data={data}
          >
            <XAxis dataKey={xLabel} />
            <YAxis />
            <Tooltip />
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="monotone" dataKey={yLabel} stroke="#8884d8" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default Graph;